
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  InternalProjectSummaryReportDto
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "progressReportDetail",
  components: {
    ExportWord,
    AuditButtons,
    AttachmentsView
  }
})
export default class ProgressReportDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId: number = 0;
  detail: InternalProjectSummaryReportDto = {
    project: {}
  };

  selector: string = "table-detail";
  filename: string = "项目总结报告";
  styles: string = "";

  hostType = AttachmentHostType.Internal_Project_Summary_Report

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.InternalProjectSummaryReport,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  fetchAuditData() {
    return api.internalProjectSummaryReports.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.internalProjectSummaryReports
      .get({ id: this.dataId })
      .then(res => {
        this.detail = { ...res };
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
