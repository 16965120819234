
import {Component, Vue} from "vue-property-decorator";
import Tinymce from "@/components/Tinymce/index.vue";
import api from "@/api";
import {AttachmentHostType, InternalProjectSummaryReportCreateOrUpdateDto} from "@/api/appService"
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";

@Component({
  name: "InternalProjectSummaryReports",
  filters: {
    getSum(list: any[]) {
      let sum: number = 0;
      list.map((item, index) => {
        sum += item.money;
      });
      return sum;
    }
  },
  components: {
    ProjectSelect,
    MultipleUploadFile,
    Tinymce,
    Ueditor
  }
})
export default class InternalProjectSummaryReports extends Vue {
  projectYear: string | undefined = '';
  dataId!: number;
  defaultData: InternalProjectSummaryReportCreateOrUpdateDto = {
    projectId: undefined,
    year: undefined,
    userName: undefined,
    summary: undefined,
    savedAttachments: [],
    id: 0
  };

  form: InternalProjectSummaryReportCreateOrUpdateDto = {...this.defaultData};

  disabledProject: boolean = false;

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Summary_Report;
  }

  created() {
    if (this.$route.query.projectId) {
      this.form.projectId = Number(this.$route.query.projectId);
      this.disabledProject = true;
    }
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.form.id = Number(this.$route.params.id);
      this.fetchDetail()
    }
  }

  fetchDetail() {
    api.internalProjectSummaryReports.get({id: this.form.id}).then(res => {
      this.form = {...res};
      this.projectYear = res.year + '';
      if (!this.form!.savedAttachments) {
        this.form!.savedAttachments = []
      }
    });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.year = Number(this.projectYear)
        if (this.dataId) {
          await api.internalProjectSummaryReports.update({
            body: {...this.form}
          }).then(() => {
            this.$message.success("更新成功");
            this.$router.back();
          });
        } else {
          await api.internalProjectSummaryReports.create({
            body: {...this.form}
          }).then(() => {
            this.$message.success("新建成功");
            this.$router.back();
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "请选择项目",
        trigger: "blur"
      }
    ]
  };
}
