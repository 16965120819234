
import {Component, Ref, Vue} from "vue-property-decorator";
import {AuditFlowScope, ExternalDueDiligenceRecordDto} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import {AuditBodyDto} from "@/utils/customDto";

@Component({
  name: "dueDiligenceRecordDetail",
  components: {
    ExportWord,
    AuditButtons
  }
})
export default class DueDiligenceRecordOrganizationDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dueDiligenceRecordId?: number;
  detail: ExternalDueDiligenceRecordDto = {};
  visitObjects: any = [{role: undefined, name: undefined, position: undefined}]

  selector: string = "table-detail";
  filename: string = "项目尽职报告";
  styles: string = "";

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.DueDiligenceRecord,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }

  created() {
    if (this.$route.params.id) {
      this.dueDiligenceRecordId = Number(this.$route.params.id);
      this.fetchDetail();
    }

  }

  fetchData() {
    return api.externalDueDiligenceRecord.audit;
  }


  // 获取详情
  async fetchDetail() {
    await api.externalDueDiligenceRecord.getDetail({id: this.dueDiligenceRecordId}).then(res => {
      this.detail = {...res};
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }
      this.visitObjects = JSON.parse(this.detail.visitObjectJson!);
      console.log(this.visitObjects)
    });
  }

  changeAudit() {
    this.fetchDetail()
  }

  // 返回
  cancel() {
    this.$router.back();
  }


}
